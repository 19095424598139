<template>
  <div>
    <div v-if="isHost && publisher.user" class="field-box">
      <div class="field-name fs28 fc666">
        发布人<span class="desc">主持人可代私董登记帮助内容</span>
      </div>
      <div class="field-content" @click.stop="showPopup = true">
        <span class="selected-user  fs28">{{ publisher.user.name || "" }}</span>
        <van-icon name="arrow" size=".28rem" />
      </div>
    </div>
    <div class="title fs28 fc666">登记内容</div>
    <div class="inner help">
      <van-field
        v-model.trim="message"
        type="textarea"
        rows="20"
        placeholder="请填写你能为案主提供的帮助内容"
      />
    </div>
    <div class="help-buttom-b-wrap">
      <div @click="onSubmit" class="help-buttom-b dfc flex-center">
        <icy-button height="0.8rem" border-radius="0.44rem"
          >登记帮助内容</icy-button
        >
      </div>
    </div>
    <van-popup v-model="showPopup" position="top" :style="{ height: '6rem' }">
      <div
        v-for="item in list"
        :key="item.id"
        class="option-wrap"
        @click.stop="onSelect(item)"
      >
        <div class="left">
          <img :src="item.user.avatar" class="avatar" />
          <div class="name">{{ item.user.name }}</div>
        </div>
        <div class="right">
          <van-icon
            v-if="publisher.user_id === item.user_id"
            name="success"
            color="#e3b06b"
            size=".36rem"
          />
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Dialog } from "vant";
export default {
  data() {
    return {
      message: "",
      id: "",
      user_id: 0,
      // 参会私董列表
      list: [],
      // 发布人
      publisher: {
        user: {},
        user_id: null
      },
      isHost: 0,
      showPopup: false,
      routerType: 0
    };
  },
  async created() {
    this.$title("登记帮助内容");
    this.id = parseInt(this.$route.query.id) || "";
    this.isHost = parseInt(this.$route.query.isHost) || 0;
    this.routerType = parseInt(this.$route.query.type) || 0;
    if (this.isHost) {
      await this.initUserInfo();
      this.initList();
    }
  },
  mounted() {},
  methods: {
    onSubmit() {
      if (!this.message) {
        this.$toast("请填写你能为案主提供的帮助内容");
        return;
      }
      if (!this.id) {
        this.$toast("未找到对应会议");
        this.$goReplace("home");
        return;
      }
      const data = {
        id: this.id,
        content: this.message
      };
      // 主持人代发布
      if (this.publisher.user_id && this.publisher.user_id !== this.user_id) {
        Dialog.confirm({
          title: "代登记确认",
          message: `请与”${this.publisher.user.name}“确认后发布`,
          confirmButtonColor: "#e3b06b"
        }).then(() => {
          data["user_id"] = this.publisher.user_id;
          this.helpSubmit(data);
        });
      } else {
        this.helpSubmit(data);
      }
    },
    async helpSubmit(data) {
      const resp = await this.$API
        .post("addHelpContent", data)
        .then(resp => resp);
      if (resp.code == 1000) {
        this.$toast.success("添加成功");
        this.$goReplace("meetingDetail", {
          active: 3,
          id: this.id,
          type: this.routerType
        });
      }
    },
    async initList() {
      const data = {
        id: this.id
      };
      const resp = await this.$API
        .get("joinMeetingSidongList", data)
        .then(resp => resp);
      if (resp.code == 1000) {
        this.list = [...this.list, ...resp.data.list];
      }
    },
    // 获取用户
    async initUserInfo() {
      const resp = await this.$API.get("userInfo").then(resp => resp);
      if (resp.code == 1000) {
        const id = resp.data.info.id;
        this.user_id = id;
        this.publisher = {
          user: resp.data.info,
          user_id: id
        };
        this.list.push(this.publisher);
      }
    },
    onSelect(item) {
      this.publisher = item;
      this.showPopup = false;
    }
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.title {
  padding: 0.36rem;
  height: 1rem;
}
.inner {
  width: 6.78rem;
  margin: 0 auto;
  min-height: calc(100vh - 1rem - 1.2rem - 1.12rem);
}
.field-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.36rem;
  height: 1.12rem;

  .field-name {
    display: flex;
    align-items: center;
    flex-shrink: 0;

    .desc {
      margin-left: 0.1rem;
      font-size: 0.22rem;
      color: #ccc;
    }
  }

  .field-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
  }
}
.help-buttom-b-wrap {
  width: 7.5rem;
  height: 1.2rem;
  .help-buttom-b {
    position: fixed;
    bottom: 0;
    margin: 0 auto;
    padding: 0.2rem 0;
    width: 7.5rem;
    height: 1.2rem;
  }
}
.option-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem 0.36rem;
  .left {
    display: flex;
    align-items: center;

    .avatar {
      margin-right: 0.3rem;
      flex-shrink: 0;
      width: 1rem;
      height: 1rem;
      object-fit: cover;
      border-radius: 50%;
    }

    .name {
      color: #333;
      font-size: 0.32rem;
    }
  }
}
</style>
